$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * cookie-message
	// *
	// *
	var cookieMessage = Cookies.get('cookie-message');

	$(".js-cookie-message-btn").click(function(){
		$(this).parents(".js-cookie-message").fadeOut();
		Cookies.set('cookie-message', '1', { expires: 365 });
	});

	if (!cookieMessage) {
		$(".js-cookie-message").show();
	}


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 60; // Distance from Browserbottom where the animation should start

    function fadeInElements(){
        var viewPort = $(window).scrollTop() + $(window).height();

        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top;

            if ((elementTop + offset) <= viewPort) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
                $(this).find(".js-video-button").trigger("click");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * video
    // *
    // *
    $(".js-video-button").click(function(){
        $(this).fadeOut().siblings().get(0).play();
        $(this).siblings().attr("controls", true).prop("muted", false);
        $(this).parents(".js-video-wrapper").removeClass("animateIn animateIn--active");
    });

    



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * mobileNavigation
	// *
	// *
    $(".js-nav-mobile-button").click(function(){
        $(".js-nav-mobile").toggleClass("active");
        $('html, body').toggleClass('freeze');
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * popup
    // *
    // *
    $(".js-popup-button").click(function(){
        $(this).parents(".js-popup-parent").find(".js-popup").fadeToggle();
        $("body, html").toggleClass("freeze");
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * cookie popup
    // *
    // *
    if ($(".js-cookie-popup").length) {
        var popup = sessionStorage.getItem("popup");

        if (popup != 1) {
            $(".js-cookie-popup").fadeIn();
            $("body, html").addClass("freeze");
        }

        $(".js-cookie-popup-button").click(function(){
            $(this).parents(".js-popup-parent").find(".js-cookie-popup").fadeToggle();
            $("body, html").removeClass("freeze");
            sessionStorage.setItem("popup",1);
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * productSlider
    // *
    // *
    var $imageSlider = $(".js-image-slider");

    if ($imageSlider.length){
        $imageSlider.slick({
            fade: false,
            dots: false,
            arrows: true,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 8000,
            prevArrow: $(".js-image-slider-button-prev"),
            nextArrow: $(".js-image-slider-button-next")
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * ratingCarousel
    // *
    // *
    var ratingCarousel = $(".js-rating-carousel");

    if (ratingCarousel.length){
        ratingCarousel.slick({
            fade: false,
            dots: false,
            arrows: true,
            autoplay: true,
            slidesToShow: 3,
            speed: 1000,
            autoplaySpeed: 8000,
            prevArrow: $(".js-rating-carousel-button-prev"),
            nextArrow: $(".js-rating-carousel-button-next"),
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Nav Active when Element is in Viewpoint
    // *
	// *
	var $sections = $(".nav-section"),
    	$nav = $(".js-nav-wrapper"),
    	navHeight = $("header").outerHeight();

	$(window).on('scroll', function () {
		var cur_pos = $(this).scrollTop() + 40;

      	$sections.each(function() {
			var top = $(this).offset().top - navHeight, bottom = top + $(this).outerHeight();

			if (cur_pos >= top && cur_pos <= bottom) {
				$nav.find('li').removeClass('active');
				$sections.removeClass('active');
				$(this).addClass('active');
				$nav.find('a[href="#'+$(this).attr('id')+'"]').parent('li').addClass('active');
			}
		});
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * avoid default browsers anchor jumps and scroll to
    // *
    // *
    if (window.location.hash) {
        var hash = window.location.hash;

        setTimeout(function(){
            $('html').animate({
                scrollTop: $(hash).offset().top - 100
            }, 1000);
        }, 400);

    }


});
